var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-start justify-center cardforgraph",
      attrs: { id: "graphColab2" },
    },
    [
      _c("apexCharts", {
        staticClass: "apexchart",
        staticStyle: { "min-height": "80px !important" },
        attrs: {
          width: "100%",
          height: "111px",
          options: _vm.chartOptions,
          series: _vm.serie,
          type: "donut",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }