<template>
  <div class="d-flex align-start justify-center cardforgraph" id="graphColab2">
    <apexCharts
      class="apexchart"
      style="min-height: 80px !important"
      width="100%"
      height="111px"
      :options="chartOptions"
      :series="serie"
      type="donut"
    />
  </div>
</template>

<script>
import ThemeMixin from '@/mixins/Theme.vue';
import 'dayjs/locale/pt-br';

export default {
  name: 'DonutForFilter',

  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('../../indicadores/components/NoData.vue'),
  },

  mixins: [ThemeMixin],

  data() {
    return {
      serie: [1],
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        states: {
          active: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
        },
        plotOptions: {
          pie: {
            customScale: 0.98868,
            expandOnClick: false,
          },
        },
        tooltip: {
          enabled: false,
        },
        stroke: {
          colors: ['#E7E7FA'],
        },

        legend: {
          show: false,
          position: 'top',
          showForSingleSeries: false,
          showForZeroSeries: true,
          horizontalAlign: 'center',
          markers: {
            width: 12,
            height: 12,
          },
          labels: {
            show: false,
            colors: '#575767',
          },
        },
        colors: ['#f7f7fd'],
      },
    };
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
.cardforgraph {
  /* background-color: #f7f7fd; */
  height: 80px !important;
  width: 80px !important;
  min-width: 80px !important;
  max-width: 80px !important;
}
</style>
